import React from "react";
import ModalImage from "react-modal-image";

const Event = () => {

    const eventPhotos = [
        { url: require('../asset/images/event/Event_2.jpg'), caption: 'SSLC RESULTS 2023-24' },
        { url: require('../asset/images/event/Event_1.jpg'), caption: 'ABACUS COMPETITIONS' },
        { url: require('../asset/images/event/Event_3.jpg'), caption: 'PSYCHOLOGICAL WORKSHOP IN OUR SCHOOL' },
        { url: require('../asset/images/event/Event_4.jpg'), caption: '' },
        { url: require('../asset/images/event/Event_5.jpg'), caption: '' },
        { url: require('../asset/images/event/Event_6.jpg'), caption: '' },
        { url: require('../asset/images/event/Event_7.jpg'), caption: '' },
        { url: require('../asset/images/event/Event_8.jpg'), caption: '' },
        { url: require('../asset/images/event/Event_9.jpg'), caption: '' },
        { url: require('../asset/images/event/Event_10.jpg'), caption: '' },
        { url: require('../asset/images/event/Event_11.jpg'), caption: '' },
        { url: require('../asset/images/event/Event_12.jpg'), caption: 'FIELD TRIP TO SUGARCANE RESEARCH STATION - MELALATHUR' },
        { url: require('../asset/images/event/Event_13.jpg'), caption: '' }

    ]

    return (
        <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
            {eventPhotos.map((img, index) => (
                <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-3 position-relative d-flex align-items-center">
                    <ModalImage
                        hideDownload
                        
                        hideZoom
                        className="img-fluid"
                        small={img.url}
                        large={img.url}
                        alt={img.caption}
                    />

                    {img.caption && (
                        <div className="position-absolute w-100 " style={{ bottom: 0, left: 0, background: 'rgba(255, 255, 255, 0.7)' }}>
                            <label className="font-weight-bold  p-1" style={{ fontSize: 16 }} >{img.caption}</label>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default Event