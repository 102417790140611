import React, { useEffect, useState } from "react";
import '../styles/aboutus.css'
import { DeskCard } from "../component/deskcard";


const AboutUs = () => {

    const chairmanContent = (
        <>            <label> Our dedicated teachers and teaching assistants are well experienced, and work oriented team to make the school a very special place, everyday, for your child. One of the greatest gifts, a parent can give to their children is good education. We have established our institutions with the sole objective of providing the best possible education leaving "No stones unturned". We know that the "Wealth of knowledge is unequalled" and we are dedicated making our students knowledgeable in several fields. </label>
         <label  className="d-flex justify-content-center"> <strong> Our Main Motto</strong></label>   
            <label style={{ fontStyle: 'italic' }}>
                <strong>"</strong>OUR SCHOOL'S MOTTO TO UPLIFT THE RURAL STUDENTS & SOCIO-ECONOMIC BACKWARD TO HIGHER THROUGH OUR EDUCATION & DISCIPLINE,ALSO THROUGH PRACTICAL & CONCEPTUAL LEARNING<strong>"</strong>
            </label>
            <div className="d-flex justify-content-start mt-2">
                <strong> Thiru.V.A.GOVINDAN  <span style={{fontSize:14}}>M.A.,M.Ed.,</span></strong>

            </div>
        </>

    )
    const secretaryContent = (
        <>            <label> To discover the hidden potential of every individual and prepare them to handle challenges in the fast changing world.
        </label>
            <label>To enrich the student community with the sound foundation of knowledge, life skills, create social consciousness and make them responsible individuals who would grow to be the leaders of tomorrow.</label>
            <label>
                The motto of the school signifies discipline, courage and excellence.The children of our L.G.Matric school are determined to develop a wholesome personality inculcating good values and character. Values are developed to the maximum through curricular and co-curricular activities. We lay emphasis on impeccable discipline which mounts courage in the souls of an individual leading towards excellence in life.
            </label>
            <div className="d-flex justify-content-start mt-2" >
                <strong> Tmt.A.LALITHA GOVINDAN <span style={{fontSize:14}}>M.A., B.ED.,RBP(Hin)</span> </strong>


            </div>
            <div className="d-flex justify-content-start" >
                <p > “EDUCATION MAKES PERFECTION”</p>


            </div>

        </>

    )
    const corresponentContent = (
        <>            <label> Education in its true sense, should form character; increase the strength of mind; expand the intellect and make on stand on one's own feet.The supreme art of the teachers is to awake joy in creative expression and knowledge. The congenial and cordial atmosphere is inevitable to arouse intrest in the students to enable them to withstand viscidities in pursuit of education. Our aim is quality education for all. The education, we impart among our students helps them find lasting solutions to many challenges confronting the world today. We lay emphasis on the fact that quality is never an accident; it is always the result of high intention, sincere effort, intelligent direction and skilful execution; it represents the wish choice of many alternatives.</label>

            <div className="d-flex justify-content-start mt-2">
                <strong> Dr.G.NAVEENPRABHU  <span style={{fontSize:14}}> M.Sc.,M.A.,M.Phil B.Ed.,M.B.A.,DIBT.,D.Litt.,</span></strong>

            </div>
        </>

    )

    return (
        <div classname='bg-smoke'>
            <div className=" justify-content-center d-flex align-items-center" style={{ height: 60, fontWeight: 700, fontSize: 30, color: '#ff512f', textShadow: '2px 3px white' }}>
                About Us
            </div>
            <DeskCard title={'Chairman’s Desk'} image={require('../asset/images/face/chairman.png')} content={chairmanContent} />
            <DeskCard title={`Secretary's DESK`}  image={require('../asset/images/face/secretary.png')} content={secretaryContent} />
            <DeskCard title={`Correspondents’s Desk`}  image={require('../asset/images/face/corres.png')} content={corresponentContent} />
        </div>
    )
}
export default AboutUs