import React from "react";
import { Col, Row } from "react-bootstrap";

const ContactUs = () => {

    return (
        <div>
            <div className="justify-content-center d-flex align-items-center" style={{ height: 60, fontWeight: 700, fontSize: 30, color: '#ff512f', textShadow: '2px 3px white' }}>
                Contact Us
            </div>
            <Row className="d-flex justify-content-around align-items-stretch">
                <Col xs={12} sm={5} md={5} lg={5} xl={5} className="m-1" >
                    <div className="card-body ">

                        <div className="p-2" style={{ borderLeft: '5px solid #ff512f' }}>
                            <label className='d-flex ' style={{ textAlign: 'left' }}><strong>THE CORRESPONDENT
                            </strong>,</label>
                            <label className='d-flex ' style={{ textAlign: 'left' }}> <strong>LALETHA GOVINDHAN MATRIC SCHOOL </strong>,</label>
                            <label className='d-flex ' style={{ textAlign: 'left' }}>GUDIYATTAM TO MADHANUR ROAD ULLI VILLAGE & POST </label>
                            <label className='d-flex ' style={{ textAlign: 'left' }}>  GUDIYATTAM TK, VELLORE, TAMIL NADU-635813.</label>


                            <label className='d-flex' style={{ textAlign: 'left' }}><i className="material-icons" style={{ color: '#ff512f', marginRight: 10 }}>contact_mail</i><a href="mailto:lgmatricschool@gmail.com">lgmatricschool@gmail.com </a> </label>

                            <label className='d-flex ' style={{ textAlign: 'left' }}>  <i className="material-icons" style={{ color: '#ff512f', marginRight: 10 }}>call</i> <a href="tel:04171292237">04171-292237 </a>  </label>
                            <label className='d-flex ' style={{ textAlign: 'left' }}>  <i className="material-icons" style={{ color: '#ff512f', marginRight: 10 }}>call</i> <a href="tel:8903748806">8903748806 </a>  </label>
                        </div>

                    </div>
                </Col>
                <Col xs={12} sm={5} md={5} lg={5} xl={5} className="m-1" >
            
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.1910622036535!2d78.84049517464953!3d12.895432687412935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bad13cb49fa9cad%3A0xa1d9fb868a479801!2sLALETHA%20GOVINDHAN%20MATRIC%20SCHOOL!5e0!3m2!1sen!2sin!4v1714474247092!5m2!1sen!2sin" width="100%" height="100%" style={{border:0,borderRadius:10}} allowfullscreen="" loading="lazy"  referrerpolicy="no-referrer-when-downgrade"></iframe>
          
                </Col>

            </Row>

        </div>
    )
}

export default ContactUs