
export const DeskCard = (props) => {
    console.log(props)
    return (
        <div className="card-body m-3" style={{position:'relative'}}>
        <div className='d-flex aboutus' style={{margin:'0 auto'}}>
            <div className="curved-image-div"  >
            <div className="curved-image"  style={{backgroundImage:`url(${props.image})`}}></div>
                </div>
        
          <div className="curved-text">
            {props.content}
          </div>
        </div>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <label className="card-title" style={{ padding: 5 }}>
            {props.title}
          </label>
        </div>
      </div>
    )
}

export const DeskCard1 = (props) => {
    return (
        <div class="container">
            <div class="card">
                <div class="imgBx">
                    <img src={props.image} />
                </div>
                <div class="contentBx">
                    <h2>Nike Shoes</h2>
                    <div class="size">
                        <h3>Size :</h3>
                        <span>7</span>
                        <span>8</span>
                        <span>9</span>
                        <span>10</span>
                    </div>
                    <div class="color">
                        <h3>Color :</h3>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <a href="#">Buy Now</a>
                </div>
            </div>
        </div>
    )
}