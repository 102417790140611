import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const Home = () => {

    const [show, setShow] = useState(false)
    const [eventlist, setEventList] = useState([])
    useEffect(() => {
        fetchExcel()
    }, [])

    async function fetchExcel() {

        const res = await fetch('https://script.google.com/macros/s/AKfycbylWOtBPhZrRjj9I94_ms0-2PMm1Rn7HXU7ocsed8YcPrg9YKvhI73ixcdxGVf7E2iB/exec?action=Event')
        res.json().then((re) => {
            console.log(re.filter(i => typeof i === 'string' && i.length))
            setShow(true)
            setEventList(re.filter(i => typeof i === 'string' && i.length))
        })


    }
    const images = [
        { src: require('../asset/images/event/Event_2.jpg'), content: 'Results 2023-24' },
        { src: require('../asset/images/school/class_united.jpg'), content: 'Classroom' },
        { src: require('../asset/images/school/classroom1.jpg'), content: 'Classroom' },
        { src: require('../asset/images/school/classroom2.jpg'), content: 'Classroom' },
        { src: require('../asset/images/school/classroom3.jpg'), content: 'Classroom' },
        { src: require('../asset/images/school/library.jpg'), content: 'Library' },
        { src: require('../asset/images/school/lab_boys.jpg'), content: 'Labratory' },
        { src: require('../asset/images/school/lecture1.jpg') },
        { src: require('../asset/images/school/lab_girls.jpg'), content: 'Labratory' },
        { src: require('../asset/images/school/school_front_bus1.jpg'), content: 'Placeholder' },
    ]
    return (
        <div className="col-12" >
            <div className='d-flex m-3 p-1'  >
                <img className="homescl1" style={{ borderRadius: 10, padding: 5, background: 'white', border: '2px solid rgb(255, 81, 47)' }} src={require('../asset/images/school/school_front1_edited.jpg')} width={'100%'} />
            </div>
            <div>
                <Carousel className="m-3 carousel-home"    >
                    {images.map((img) => {
                        return (
                            <div>
                                <img src={img.src} style={{ width: '100%' }} />
                                {img.content && <p className="legend">{img.content}</p>}
                            </div>
                        )
                    })

                    }

                </Carousel>
            </div>
            {eventlist.length ? <div className="card-body m-3 p-1" style={{ overflow: 'auto', maxheight: 300,background:'cornsilk' }}>
                <label style={{ fontWeight: 500, fontSize: 20 }}>Events Board </label>
                <ul className="custom-event-ul">


                    {eventlist.map((event, index) => {
                        return (
                            <li >
                                <label className="d-flex justify-content-start" > {event}</label>
                            </li>



                        )
                    })

                    }
                </ul>
            </div> : ''}

        </div>
    )
}
export default Home