import React from "react";
import { Row, Col } from 'react-bootstrap'

const Academics = () => {
    const academics1 = [{ title: 'We are giving education through playway method for KGs' },
    { title: ' Service Oriented Education.' },
    { title: 'No compromise on quality in education as well as discipline.' },
    { title: ' Every kid is unique, we explore them.' },
    { title: 'Unlocking their capabilities, Comfortable classes, Play ground, Lab, Library sectors.' },
    { title: ' MoU signed with BEEHIVE Communication club to enhance the pupil in Spoken English.' },
    { title: 'Having 40 Years of Teaching Experience.' },
    ]

    const academics2 = [
        { title: ' Implying only the needed things.' },
        { title: 'We are not encouraging unwanted expenses for parents.' },
        { title: 'LAB ACTIVITIES……USAGE OF LIBRARY…..' },
        { title: 'Engaging Audio visual classes' },
        { title: 'Gardening classes… Green wings team for Leadership…..' },
        { title: 'Giving confidence to Students by engaging themselves in Seminar classes….Presentations…… Posters….' },
        { title: 'Affordable & Flexible fee structure.' }
    ]
    const academics3 = [

        { title: ' Transport facilities available all over the surrounding living areas.' },
        { title: 'Come to our school once & then decide.' },
        { title: ' Importance given to Sports activities also.' },
        { title: 'HINDI,ABACUS,YOGA & WELLNESS TRAINING WILL BE GIVEN' },
        { title: 'SPECIAL TRAINING WILL BE GIVEN FOR HANDWRITING' },
        { title: 'INDIVIDUAL ATTENTION WILL BE GIVEN FOR EVERY STUDENT IS OUR MAIN MOTTO…..' }
    ]
    return (
        <div>
            <div className=" justify-content-center d-flex align-items-center" style={{ height: 60, fontWeight: 700, fontSize: 30, color: '#ff512f', textShadow: '2px 3px white' }}>
                Academics
            </div>

            <Row className="m-2"   >
                <Col  xs={12} sm={6} md={4} lg={4} xl={4} >
                    <div className="card-body" style={{ borderTop: '5px solid rgb(255, 81, 47)',background:'cornsilk' }}>
                    <ul className="custom-ul" >            {academics1.map((item) => {
                        return (
                            <li style={{ background: `url(${require('../asset/images/other/pencil.png')}) no-repeat`}}>
                                <div className='m-2 p-1 ' style={{ textAlign: 'left' }}>
                                    {item.title}
                                </div>
                            </li>
                        )
                    })}
                    </ul>
                    </div>
                </Col>
                <Col  xs={12} sm={6} md={4} lg={4} xl={4} >
                <div className="card-body" style={{ borderTop: '5px solid rgb(255, 81, 47)',background:'cornsilk' }}>
                    <ul className="custom-ul">
                        {academics2.map((item) => {
                            return (
                                <li style={{ background: `url(${require('../asset/images/other/pencil.png')}) no-repeat`}}>
                                    <div className='m-2 p-1 ' style={{ textAlign: 'left' }}>
                                        {item.title}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    </div>
                </Col>
                <Col  xs={12} sm={6} md={4} lg={4} xl={4} >
                <div className="card-body" style={{ borderTop: '5px solid rgb(255, 81, 47)',background:'cornsilk' }}>
                    <ul className="custom-ul">
                        {academics3.map((item) => {
                            return (
                                <li style={{ background: `url(${require('../asset/images/other/pencil.png')}) no-repeat`}}>
                                    <div className='m-2 p-1 ' style={{ textAlign: 'left' }}>
                                        {item.title}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    </div>
                </Col>
        </Row>

        </div >

    )
}

export default Academics