import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

const Header = () => {
    const [expanded, setExpanded] = useState(false);
const location = useLocation()

    return (
        <div>
            <img src={require('../asset/images/school/school_name.jpg')} width={'100%'} />
            <div style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' }}>
                <Navbar  expand="md" expanded={expanded} className="mb-1 bg-orange" >
                    <Container>
                        {/* <Navbar.Brand as={Link} > <div style={{width:80}}><img width={'100%'} src={require('../asset/images/school/logo.png')}  /></div>  </Navbar.Brand> */}
                        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto  col-12 flex justify-content-evenly">
                                <Nav.Link className={'borderedge ' + (location.pathname === '/' && 'active-page' )} as={Link} to="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
                                <hr/>
                                <Nav.Link className={'borderedge ' + (location.pathname.includes('/aboutus') && 'active-page' )} as={Link} to="/aboutus" onClick={() => setExpanded(false)}>About Us</Nav.Link>
                                <Nav.Link className={'borderedge ' + (location.pathname.includes('/academics') && 'active-page' )} as={Link} to="/academics" onClick={() => setExpanded(false)}>Academics</Nav.Link>
                                <Nav.Link className={'borderedge ' + (location.pathname.includes('/events') && 'active-page' )} as={Link} to="/events" onClick={() => setExpanded(false)}>Events</Nav.Link>
                                {/* <Nav.Link className={'borderedge ' + (location.pathname.includes('/fee-payment') && 'active-page' )} as={Link} to="/fee-payment" onClick={() => setExpanded(false)}>Fee Payment</Nav.Link> */}
                                <Nav.Link className={'borderedge ' + (location.pathname.includes('/admission') && 'active-page' )} as={Link} to="/admission" onClick={() => setExpanded(false)}>Online Admissions</Nav.Link>
                                <Nav.Link className={'borderedge ' + (location.pathname.includes('/contact-us') && 'active-page' )} as={Link} to="/contact-us" onClick={() => setExpanded(false)}>Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <Outlet />
            <div className='mt-5'>
            <div className="card-body justify-content-center d-flex align-items-center" style={{height:75,fontWeight:500,fontSize:14,color:'white',borderRadius:0, background:'#FF9130'}}>
            Copyright © 2024 &nbsp; <strong>LALETHA GOVINDHAN MATRICULATION SCHOOL,ULLI,GUDIYATTAM,VELLORE-635813 </strong> 
            </div>

            </div>

        </div>
    );
};

export default Header;
