import React from "react";

const Admission = ()=>{

    return (
        <div className="col-12" style={{backgroundImage:`url(${require('../asset/images/other/learningitembg.png')})`, backgroundSize:'contain' }} >

            <iframe style={{background:'#fff8dc90'}} src="https://docs.google.com/forms/d/e/1FAIpQLScIv7kd45s_zJICVqu1i8xh0hdIPuluWGejnbDZz0I94dcIQA/viewform?embedded=true" width="100%" height="1765" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    )
}
export default Admission