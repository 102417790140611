import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './component/header';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/styles/custom.css'
import Home from './page/Home';
import AboutUs from './page/AboutUs';
import Academics from './page/Academics';
import ContactUs from './page/ContactUs';
import Event from './page/Event';
import Admission from './page/Admission';
function App() {
  return (
    <div className="App"  >
      <BrowserRouter>
        <Routes>
          <Route element={<Header />} >
            <Route path="/" element={<Home />} />
          </Route>
          <Route element={<Header />} >
            <Route path="/aboutus" element={<AboutUs />} />
          </Route>
          <Route element={<Header />} >
          
          <Route path="/academics" element={<Academics />} />
        </Route>
        <Route element={<Header />} >
        
          <Route path="/contact-us" element={<ContactUs />} />
        </Route>
        <Route element={<Header />} >
        
        <Route path="/admission" element={<Admission />} />
      </Route>
        <Route element={<Header />} >
        
        <Route path="/events" element={<Event />} />
      </Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
